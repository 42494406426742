<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="24">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdName">搜索</div>
              <div class="fl">搜索词： <span class="search_hd_txt02">{{keyword}}</span></div>
              <div class="fl">找到相关新闻 <span class="search_hd_txt01">{{keyTotal}}</span><em> 篇</em></div>
              <div class="fl">搜索时间：<span class="search_hd_txt01">{{keyTime}} 秒</span></div>
              <div class="fr listHdGrop"><a :href="`${urlName}`">首页</a> · <span class="txt">搜索</span> </div>
            </div>
            <div class="listContainer">
              <ul class="listList">
                <li v-for="(item, index) in listData" :key="index">
                  <a class="clearfloat" :href="item.is_link === 1 ? item.link_url : `${urlName}detail?id=${item.id}`" target="_blank">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line" v-html="item.title">
                    </div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div>
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      keyword: '',
      keyTotal: '',
      keyTime: '',
      list: [],
      currentPage: 1,
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '政策法规',
        sub: 'POLICIES',
      },
      slideList:[
        {
          title: '相关法规',
          id: '1'
        },
        {
          title: '行业规范',
          id: '2'
        }
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: []
    }
  },
  mounted(){
    var _this = this;
    _this.keyword = _this.$route.query.title;
    _this.getData();
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
      this.pageNum = e
      this.getData()
    },
    async getData() {
      var _this = this;
      var startTime = new Date();
      var title = _this.$route.query.title;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        {
          page: _this.pageNum,
          title: title,
          company_id: this.companyId,
        }
      );
      var endTime = new Date();
      _this.keyTime =(endTime.getTime()-startTime.getTime())/1000; 
      _this.total = data.data.total;
      _this.keyTotal = data.data.total;
      _this.list = data.data.list;
      // item.title.replace(/集团/g, `<span style="color: #b30f00">${keyword}</span>`)
      var resultsList = _this.list;
      resultsList.map((item, index) => {
        // console.log('item', item)
        if (_this.keyword && _this.keyword.length > 0) {
          // 匹配关键字正则
          let replaceReg = new RegExp(_this.keyword, 'g')
          // 高亮替换v-html值
          let replaceString =
            '<span style="color: #b30f00">' + _this.keyword + '</span>'
          resultsList[index].title = item.title.replace(
            replaceReg,
            replaceString
          )
        }
      })
      console.log(resultsList)
      _this.listData = [];
      _this.listData = resultsList;
    }
  }
}
</script>
<style scoped>
.listHd .fl {
  margin-right: 15px;
}
.listHd .fl.listHdName {
  margin-right: 30px;
}
.search_hd_txt02 {
  font-weight: bold;
}
.search_hd_txt01 {
  color: #ff0000;
}
</style>